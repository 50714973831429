import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TryOutlined from '@mui/icons-material/TryOutlined';
import CardCover from '@mui/joy/CardCover';

function createMarkup(html) {
    return { __html: html };
}

function TopicCard({ title, description, onClick }) {
    return (
        <Card size="sm" variant="outlined" sx={{ margin:'15px 0', boxShadow: 0 }}>
            <CardActionArea onClick={onClick}>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" sx={{ fontSize:'18px' }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={createMarkup(description)}>
                        
                    </Typography>
                    <IconButton
                        aria-label="{ title }"
                        variant="plain"
                        color="success"
                        size="sm"
                        sx={{ position: 'absolute', top: '0.275rem', right: '0.5rem' }}
                        >
                        <TryOutlined />
                    </IconButton>
                </CardContent>
                
            </CardActionArea>
        </Card>
    );
}

export default TopicCard;
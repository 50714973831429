import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import AppBarComponent from '../components/AppBarComponent';
import ChatSection from '../components/ChatSection';
import useCompanySubscriptions from '../hooks/useCompanySubscriptions';
import useChatSubscriptions from '../hooks/useChatSubscriptions';
import { useAuth } from '../context/AuthContext';
//import { Routes, Route } from 'react-router-dom';
//import CompanySettings from '../components/CompanySettings';
//import FacilityImages from '../components/FacilityImages';
//import ChatStats from '../components/ChatStats';

const DashboardPage = () => {
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [companies, setCompanies] = useState([]); // This would be fetched probably
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState({}); // Messages stored by chatUUID
    const [unreadMessages, setUnreadMessages] = useState({});
    const { userDetails } = useAuth();
    const [initialUserSet, setInitialUserSet] = useState(false);

    const selectedCompanyRef = useRef(selectedCompany);
    const lastChatFetchCompanyRef = useRef(null);

    const LoadingIndicator = () => <div>Loading...</div>;  // Customize as needed

    //let fetchedCompanies = [];

    useEffect(() => {
        // You can perform other actions here as needed based on userDetails
        if(!initialUserSet && userDetails) {
            //console.log("Updated userDetails:", userDetails);
            
            // turn userDetails.companies into fetchedCompanies
            let fetchedCompanies = userDetails.companies.map(company => {
                return { uuid: company.company_uuid, name: company.company_name };
            });
            

            setCompanies(fetchedCompanies);
            setSelectedCompany(fetchedCompanies[0]);
            setInitialUserSet(true);
        }
    }, [userDetails, initialUserSet]);

     
    
    

    useEffect(() => {
        //console.log("Is loading:", isLoading);
        //setIsLoading(true);
        // Simulate fetching companies
        //setCompanies(fetchedCompanies);
        //setSelectedCompany(fetchedCompanies[0]);
    }, [isLoading]);

    useEffect(() => {
        const fetchChats = async () => {
            
            if(isFirstLoad) {
                setIsLoading(true);
                setUnreadMessages({});
            }
            try{
                if(selectedCompany.uuid === lastChatFetchCompanyRef.current) {
                    //console.log("Company has not changed, skipping fetch");
                    return;
                }
                //console.log("Fetching chats for company:", selectedCompany.uuid);
                lastChatFetchCompanyRef.current = selectedCompany.uuid;
                const response = await fetch(`${process.env.REACT_APP_CHAT_SERVER_URL}/chats/${selectedCompany.uuid}?destination=web`);
                //console.log(response);
                const data = await response.json();

                // Fix the issue where the first message in a chat is not from the assistant
                data.chats.forEach(chat => {
                    if (chat.messages.length > 0 && chat.messages[0].sender !== 'assistant') {
                        const firstMessage = chat.messages[0];
                        const secondMessage = chat.messages[1];
                        chat.messages[0] = secondMessage;
                        chat.messages[1] = firstMessage;
                    }
                });

                setChats(data.chats || []);
                // find the chat from data.chats that has the most recent message (last entry in data.chats.messages array with the highest chat_timestamp value)
                // and set it as the selected chat
                data.chats.sort((a, b) => {
                    const aTimestamp = a.messages.length > 0 ? new Date(a.messages[a.messages.length - 1].chat_timestamp) : new Date(0);
                    const bTimestamp = b.messages.length > 0 ? new Date(b.messages[b.messages.length - 1].chat_timestamp) : new Date(0);
                    return bTimestamp - aTimestamp;
                });
                
                setSelectedChat(data.chats[0]); // Set the first chat as active by default
            } catch (error) {
                console.error("Error fetching data:", error);
                setChats([]);
            }
            if(isFirstLoad) {
                setIsLoading(false);
                setIsFirstLoad(false);
            }
        };

        if (selectedCompany) {
            selectedCompanyRef.current = selectedCompany;
            //console.log("Posting company change message");
            //console.log("Selected company:", selectedCompanyRef.current);
            window.postMessage({
                type: 'COMPANY_CHANGE',
                company: selectedCompanyRef.current,
            }, '*'); 
            fetchChats();
        } else {
            setIsLoading(true);
        }
    }, [selectedCompany, isFirstLoad]);

    // Use custom hooks to manage subscriptions
    useCompanySubscriptions(selectedCompany?.uuid, setChats, setUnreadMessages);
    
    console.log("### Passing chats to useChatSubscriptions: ###", Array.isArray(chats), chats);
    useChatSubscriptions(selectedCompany?.uuid, chats, setChats, setMessages, setUnreadMessages, selectedChat, setSelectedChat);

    const handleCompanyChange = (event) => {
        const newCompanyUUID = event.target.value;
        
        const newCompany = companies.find(company => company.uuid === newCompanyUUID);
        setSelectedCompany(newCompany);
    };

    const handleChatSelect = (newChat) => {
        const chatUUID = newChat.chatUUID;
        //if(selectedChat && selectedChat.chatUUID === chatUUID) return;
        setSelectedChat(chats.find(chat => chat.chatUUID === chatUUID));
        setMessages(prevMessages => ({
            ...prevMessages,
            [chatUUID]: (prevMessages[chatUUID] || []).map(msg => ({ ...msg, read: true }))
        }));
    };

    const handleSendMessage = async (messageContent) => {
        if (!selectedChat) return;

        console.log(selectedChat);

        const payload = {
            chatUUID: selectedChat.chatUUID,
            message: messageContent
        };

        // Send message to server
        await fetch(`${process.env.REACT_APP_CHAT_SERVER_URL}/intervene`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        // No need to do anything fancy, since it comes through automatically via message observing

        // Update local state to show the new message
        /*setMessages(prevMessages => ({
            ...prevMessages,
            [selectedChat.chatUUID]: [...prevMessages[selectedChat.chatUUID], { content: messageContent, read: true }]
        }));*/

        /*
        const chatUUID = selectedChat.chatUUID;
        setChats(currentChats => currentChats.map(chat => 
            chat.chatUUID === chatUUID 
                ? {...chat, messages: [...chat.messages, {message: messageContent, sender:"assistant", chatUUID:chatUUID, read: true}]}
                : chat
        ));

        setSelectedChat({...selectedChat, messages: [...selectedChat.messages, {message: messageContent, sender:"assistant", chatUUID:chatUUID, read: true}]});
        */

    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
            <>
                <AppBarComponent
                    companies={companies}
                    selectedCompany={selectedCompany}
                    handleCompanyChange={handleCompanyChange}
                    userDetails={userDetails}
                />
                <ChatSection
                    chats={chats}
                    setChats={setChats}
                    selectedChat={selectedChat}
                    selectedCompany={selectedCompanyRef.current}
                    setSelectedChat={setSelectedChat}
                    onSelectChat={handleChatSelect}
                    messages={messages[selectedChat?.chatUUID] || []}
                    handleSendMessage={handleSendMessage}
                    unreadMessages={unreadMessages}
                    setUnreadMessages={setUnreadMessages}
                />
            </>
            )}
        </Box>
    );

    // Get all of the companies the user belongs to (currently hardcoded)
    // The first one will be selected by default
    // For that company, fetch all chats
    // The first chat will be active by default
    // We want to subscribe to the company channel and all of its chat channels
    // When a new chat is created (notified via the company channel), we want to update the state to show the new chat
    // When a new message is received (notified via the chat channel), we want to update the state to show the new message
    // When the user sends a message, we want to send it to the server and update the state to show the new message
    // When we change companies, we want to unsubscribe from the previous company and chat channels and subscribe to the new ones
    // When we change chats, we want to mark all messages in that chat as read

};

export default DashboardPage;
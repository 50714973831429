import React, { useState, useEffect, useRef } from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppBarComponent from './components/AppBarComponent';
import ChatSection from './components/ChatSection';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import PlatformRoute from './components/PlatformRoute';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import ImplementationPage from './pages/platform/ImplementationPage';

import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import '@fontsource/inter';

const theme = createTheme(); // customize this as needed
const drawerWidth = 240;

function App() {

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <CssBaseline />
          <Routes>
            <Route 
              key="home"
              path="/*" 
              element={
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              } 
            />
            <Route key="login" path="/login" element={<LoginPage />} />
            <Route key="platform-admin" path="/platform-admin" element={<PlatformRoute><ImplementationPage /></PlatformRoute>} />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;

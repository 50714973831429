import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';

import { Container, Box, TextField, Button, Typography, Paper, AppBar } from '@mui/material';
import { styled } from '@mui/system';

const MagicContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
//  background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
  padding: '2rem',
  textAlign: 'center',
});

const MagicPaper = styled(Paper)({
  padding: '2rem',
  borderRadius: '15px',
  boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
  background: 'rgba(255, 255, 255, 0.8)',
});

const MagicButton = styled(Button)({
  marginTop: '1.5rem',
  backgroundColor: '#6200ea',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#3700b3',
  },
});

const MagicTextField = styled(TextField)({
  marginBottom: '1rem',
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
  },
});





const ImplementationPage = () => {

    const [isLoading, setIsLoading] = useState(true);
    const LoadingIndicator = () => <div>Loading...</div>;  // Customize as needed

    useEffect(() => {
        // Perform any actions here as needed
        setIsLoading(false);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        alert('Form submitted!');
    };


    return (
        <>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
            <>
                <AppBar>
                    <Toolbar>
                        <Typography>Storable AI Chat Platform Admin</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <MuiLink component={RouterLink} to="/" sx={{ color:'#ffffff', textDecoration: 'none', mr: '20px' }}>Exit Platform Admin</MuiLink>
                    </Toolbar>
                </AppBar>
                <MagicContainer>
                    <MagicPaper>
                        <Typography variant="h4" gutterBottom>
                        Welcome to Your New Adventure!
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                        Just a few details and you’re all set to launch.
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box component="form" onSubmit={handleSubmit}>
                                <MagicTextField
                                    fullWidth
                                    label="Domain Name"
                                    variant="outlined"
                                    required
                                />
                                <MagicTextField
                                    fullWidth
                                    label="First Name"
                                    variant="outlined"
                                    required
                                />
                                <MagicTextField
                                    fullWidth
                                    label="Last Name"
                                    variant="outlined"
                                    required
                                />
                                <MagicTextField
                                    fullWidth
                                    label="Email Address"
                                    variant="outlined"
                                    required
                                    type="email"
                                />
                                <MagicButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                >
                                    Launch Now!
                                </MagicButton>
                            </Box>
                            <Box>
                                Right side stuff
                            </Box>
                        </Box>
                    </MagicPaper>
                </MagicContainer>
            </>
            )}
        </>
    );
}

export default ImplementationPage;
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Card, CardContent, Chip, Typography } from '@mui/material';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Map, { NavigationControl, useMap } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

function ChatOverview({ chat }) {
    const theme = useTheme();
    const city_state = chat.user_ip_city ? chat.user_ip_city + ', ' + chat.user_ip_state : 'Unknown City';

    const [viewport, setViewport] = useState({
        latitude: chat.user_ip_lat || 40.274400, // Default to a valid latitude if none provided
        longitude: chat.user_ip_long || -76.706200, // Default to a valid longitude if none provided
        zoom: 7.5
    });

    const [mapKey, setMapKey] = useState(Math.random().toString(36).substring(7));

    // Format date function turns timestamp into a human-readable date and time
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    }

    useEffect(() => {
        //console.log("Current viewport state:", viewport);
    }, [viewport]);

    useEffect(() => {
        // Update viewport when chat changes
        setViewport(currentViewport => ({
            ...currentViewport,
            latitude: chat.user_ip_lat || 25,  // Default fallback latitude
            longitude: chat.user_ip_long || -71  // Default fallback longitude
        }));

        setMapKey(Math.random().toString(36).substring(7));
    }, [chat]);

    const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';

    return (
        <Box sx={{ width: '300px', mr: '20px', pt:'25px' }}>
            <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex:.85, height:'150px', overflow:'hidden' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h6" sx={{ fontSize:'12px', fontWeight:'bold' }}>
                            { chat.modality == 'web' ? 'Web Chat' : chat.modality == 'phone' ? 'Phone Call' : 'Other Message' } from { city_state }
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary" component="div" sx={{ fontSize:'11px' }}>
                            Received: { formatDate(chat.messages[0].chat_timestamp) }
                        </Typography>
                    </CardContent>
                    <Box sx={{ display: 'flex', flex:1, alignItems: 'center', pl: 2, pb: 1, fontSize:'11px' }}>
                        {( chat.status == 'active' ? <Chip label="Active" color="success" variant="outlined" size="small" /> : <Chip label="Completed" color="error" variant="outlined" size="small" /> )}
                    </Box>
                </Box>
                <Box sx={{ display:'flex', flex:1, height: '150px', overflow:'hidden' }}>
                    <Map
                            key={mapKey}
                            mapboxAccessToken={mapboxToken}
                            initialViewState={viewport}
                            style={{ width: '100%', height: '100%' }}
                            mapStyle="mapbox://styles/mapbox/streets-v9"
                            onViewportChange={(nextViewport) => setViewport(nextViewport)}
                        >
                    </Map>
                </Box>
                
            </Card>
            
        </Box>
    );
}

export default ChatOverview;
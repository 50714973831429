import React, { useState } from 'react';
import { Paper, CardContent, Typography, Button, Switch, TextareaAutosize, Box, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { styled } from '@mui/system';
import FacilityCards from './chatComponents/FacilityCards';
import UnitGroupCards from './chatComponents/UnitGroupCards';
import TopicCard from './chatComponents/TopicCard';
import ThumbUpIconOutlined from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownIconOutlined from '@mui/icons-material/ThumbDownOutlined';
import IconButton from '@mui/material/IconButton';
import axios from 'axios'; // Ensure axios is installed and imported

// we need to get userUUID from the auth context
import { useAuth } from '../context/AuthContext';
import { enqueueSnackbar } from 'notistack';
import { create } from '@mui/material/styles/createTransitions';

const MessageContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  backgroundColor: '#fff',
});

const UserMessage = styled(Paper)({
  alignSelf: 'flex-start',
  backgroundColor: 'rgb(250, 250, 251)',
  borderRadius: '10px',
  marginBottom: '10px',
  maxWidth: '500px',
  elevation: 0,
  wordWrap: 'break-word',
  overflow: 'hidden',
});

const AssistantMessage = styled(Paper)(({ theme, isHovered }) => ({
  alignSelf: 'flex-end',
  backgroundColor: '#f1faff',
  borderRadius: '10px',
  marginBottom: '10px',
  maxWidth: '500px',
  elevation: 0,
  wordWrap: 'break-word',
  position: 'relative',
  boxShadow: isHovered ? '0 4px 8px rgba(0,0,0,0.2)' : 'none',
  transition: 'box-shadow 0.3s ease-in-out',
}));


const Textarea = styled(TextareaAutosize)({
  width: '100%',
  marginTop: '10px',
});

function FeedbackIcons({ onFeedback }) {
  return (
    <Box sx={{ position: 'absolute', top: '-18px', right: '5px', zIndex: 1 }}>
      <IconButton onClick={() => onFeedback(1)}><ThumbUpIconOutlined sx={{ fontSize:'16px' }} color="neutral" /></IconButton>
      <IconButton onClick={() => onFeedback(-1)} sx={{ mt:'5px', ml:'-5px' }}><ThumbDownIconOutlined color="neutral" sx={{ fontSize:'16px' }} /></IconButton>
    </Box>
  );
}

/*
function ContentOptionCard({ title, description, onClick }) {
  return (
    <div 
      className="contentOptionCard" 
      style={{
        cursor: 'pointer',
        padding: '10px',
        margin: '10px 0',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        transition: 'box-shadow 0.3s ease-in-out',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
      }}
      onClick={onClick}
      onMouseOver={(e) => {
        e.currentTarget.style.boxShadow = '0 5px 15px rgba(0,0,0,0.2)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.boxShadow = '0 2px 5px rgba(0,0,0,0.1)';
      }}
    >
        <h4 className="card-title" style={{ marginBottom: 0 }}>{title}</h4>
        <Typography variant='body2' className="card-description">{description}</Typography>
    </div>
  );
}
*/

 
function ChatDetail({ chat, onSend, onToggleAI }) {
    
    const [message, setMessage] = useState('');
    const { userDetails } = useAuth();
  
    const handleMessageSend = () => {
      onSend(message);
      setMessage('');
    };

    const [hoveredMessageId, setHoveredMessageId] = useState(null);

    const handleMouseEnter = (messageId) => {
      setHoveredMessageId(messageId);
    };

    const handleMouseLeave = () => {
      setHoveredMessageId(null);
    };

    const [feedbackData, setFeedbackData] = useState({ open: false, rating: null, messageUUID: null, feedbackModalHeadline: 'Feedback'});

    const handleFeedback = (rating, messageUUID) => {
      setFeedbackData({ open: true, rating, messageUUID, feedbackModalHeadline: rating === 1 ? 'Such a Good Bot!' : 'Bad Bot!'});
    };

    const handleFeedbackSubmit = () => {
      const { rating, messageUUID, comment } = feedbackData;

      //alert(`Feedback submitted: rating=${rating}, messageUUID=${messageUUID}, comment=${comment}, userUUID=${userDetails.user_uuid}`);
      axios.post('https://chat-server-test-bv3rnbltwq-uc.a.run.app/feedback/messages/' + messageUUID, {
        userUUID: userDetails.user_uuid,
        rating,
        comment
      }).then(response => {
        console.log('Feedback submitted:', response);
        setFeedbackData({ open: false, rating: null, messageUUID: null }); // Reset after submit
        enqueueSnackbar('Feedback submitted successfully', { variant: 'success' });
      }).catch(error => {
        console.error('Error submitting feedback:', error);
        enqueueSnackbar('Failed to submit feedback', { variant: 'error' });
      });
    };
  
    const handleFeedbackChange = (e) => {
      setFeedbackData({ ...feedbackData, comment: e.target.value });
    };

    const handleCardClick = (option) => {
      if (!option) {
        console.error("Invalid option selected", option);
        return; // Exit if option is null or undefined
      }
      console.log("Option selected:", option);
      const message = {
        role: "system",
        text: `User selected: ${option}`,
      };
      const alt_message = {
        role: "user",
        text: option,
      };

      //alert("User selected an option, so we sent OpenAI one of the following:\n" + JSON.stringify(message) + "\n\n or:\n\n" + JSON.stringify(alt_message));
      //onSend("Selected: " + option);
    };    

    // toggleAI is called on click and checks the value of the switch that called it to set the AI state
    const toggleAI = (event) => {
      const aiState = event.target.checked ? 0 : 1;
      onToggleAI(chat.chatUUID, aiState);
    };

    // nl2br is a helper function that replaces newlines with <br> tags
    const nl2br = (str) => {
        //return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
        return str;
      };

      const convertImageMarkdown = (content) => {
        const imageRegex = /!\[(.*?)\]\((.*?)\)/g;
        return content.replace(imageRegex, '<img src="$2" alt="$1" width="100%" />');
      };

    const markitup = (text) => {
      // convert markdown to html
      text = convertImageMarkdown(text);
      // bold
      text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      // italic
      text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
      // underline
      text = text.replace(/__(.*?)__/g, '<u>$1</u>');
      // strikethrough
      text = text.replace(/~~(.*?)~~/g, '<del>$1</del>');
      // code
      text = text.replace(/`(.*?)`/g, '<code>$1</code>');
      // links
      text = text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');
      // images
      text = text.replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1" />');
      // blockquote
      text = text.replace(/>(.*)/g, '<blockquote>$1</blockquote>');
      // unordered lists
      text = text.replace(/^\s*-\s(.*)$/gm, '<li>$1</li>');
      text = text.replace(/(<li>.*<\/li>)/g, '<ul>$1</ul>');
      text = text.replace(/<\/ul>\s*<ul>/g, '');
      // ordered lists
      text = text.replace(/^\s*\d+\.\s(.*)$/gm, '<li>$1</li>');
      text = text.replace(/(<li>.*<\/li>)/g, '<ol>$1</ol>');
      text = text.replace(/<\/ol>\s*<ol>/g, '');
      // paragraphs
      text = text.replace(/(.+)/g, '<p>$1</p>');
      return text;
    }

    // addHexDisplay is a helper function that adds a small color block inline immediately following any hex codes (keeping the text of the hex code) and returns the entire content block
    const addHexDisplay = (text) => {
      const hexColorRegex = /#([a-f0-9]{6}|[a-f0-9]{3})\b/ig;
      return text.replace(hexColorRegex, (match) => {
        return `${match} <span style="display: inline-block; width: 16px; height: 16px; border: 1px solid #ccc; background-color: ${match};"></span>`;
      });
      }

    const parseMessageText = (messageText) => {
        try {
          const j = JSON.parse(messageText);
          
            //console.log("Parsed Message Text");
            //console.log(j);
          return j;
        } catch (e) {
          // console.error('Error parsing message text:', e);
          // You can return a fallback or an indication of the error as appropriate
          return { text: messageText };
        }
      };

      const createMarkup = (html) => {
        return { __html: html };
      };

      function ContentToCards({ content, onClick }) {
        //const listItemRegex = /(\d+)\.\s\*\*(.*?)\*\*:\s(.*?)(?=\n(?!\d+\.))/gs;
        //const listItemRegex = /(\d+)\.\s\*\*(.*?):\*\* (.*?)(?=\n\d+\.\s|\n*$)/gs;
        const listItemRegex = /(\d+)\.\s\*\*(.*?)(?:\*\*:\s|:\*\*)\s?(.*?)(?=\n\d+\.\s|\n*$)/gs;
        const elements = [];
        let lastEndIndex = 0;

        content = addHexDisplay(content);
    
        // Execute regex and handle matches
        let match;
        while ((match = listItemRegex.exec(content)) !== null) {
            // Text before list item
            const textBefore = content.substring(lastEndIndex, match.index).trim();
            if (textBefore) {
                elements.push(<Typography key={'before-' + lastEndIndex} variant='body2' dangerouslySetInnerHTML={createMarkup(nl2br(textBefore))}></Typography>);
            }
    
            // The list item itself, now passing the onClick handler
            const title = match[2].trim();
            const description = markitup(match[3].trim());
            elements.push(<TopicCard
                            key={'list-' + match[1]} 
                            title={match[2].trim()} 
                            description={description}
                            onClick={() => onClick(title)}  // passing title as option for demonstration
                          />);
    
            lastEndIndex = listItemRegex.lastIndex;
        }
    
        // Text after the last list item
        const textAfter = content.substring(lastEndIndex).trim();
        if (textAfter) {
          elements.push(<Typography key='after-last' variant='body2' dangerouslySetInnerHTML={createMarkup(nl2br(markitup(textAfter)))}></Typography>);
        }
    
        return <div>{elements}</div>;
      }
    
    
    
    



  
    return (
        <>
      <Box sx={{ border: 1, borderRadius: '8px', borderColor: 'rgb(230, 235, 241)', overflow: 'hidden' }} >
        <MessageContainer>
          { chat.user_first_name && <Typography variant="h6">User: {chat.user_first_name} { chat.user_last_name } { chat.integratoin_type }</Typography> }
        {chat.messages.map((msg, index) => {
            const messageContent = parseMessageText(msg.message);
            const richCardContent = messageContent.text ? 
            <ContentToCards 
                key={"cards-" + index} 
                content={messageContent.text} 
                onClick={handleCardClick}  // pass the handler function here
            /> : null;

            return messageContent.render_type && messageContent.render_type === "facility_cards" ? 
              // If render_type is "facility_card", render the FacilityCard component
              <FacilityCards key={index} facilities={messageContent.facilities} /> :
              (
                messageContent.render_type && messageContent.render_type === "unit_group_cards" ? 
                <UnitGroupCards key={index} unit_groups={messageContent.unit_groups} /> :
              // Otherwise, render the regular message
              (msg.sender === 'user' ? (
                <UserMessage key={index} elevation={0} >
                  <CardContent>
                    <Typography variant="body2">{msg.message}</Typography>
                  </CardContent>
                </UserMessage>
              ) : (
                <AssistantMessage key={index} elevation={0} onMouseEnter={() => handleMouseEnter(msg.messageUUID)} onMouseLeave={handleMouseLeave} isHovered={hoveredMessageId === msg.messageUUID} >
                  <CardContent>
                    { richCardContent }
                    { hoveredMessageId === msg.messageUUID && <FeedbackIcons onFeedback={(rating) => handleFeedback(rating, msg.messageUUID)} />}
                  </CardContent>
                </AssistantMessage>
              ))
              )
              })}
        </MessageContainer>
        <Textarea
          minRows={3}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button onClick={handleMessageSend}>Send</Button>
        <div>
          <Switch
            checked={chat.ai_enabled == 0}
            onChange={toggleAI}
            color="primary"
          />
          Disable AI
        </div>
      </Box>
      <Dialog fullWidth={'true'} maxWidth={'sm'} open={feedbackData.open} onClose={() => setFeedbackData({ ...feedbackData, open: false })}>
        <Box display="flex" flexDirection="row" maxHeight={'300px'} sx={{ overflow:'hidden', maxHeight:'300px' }}>
          <Box display="flex" flex={1} p={0} flexDirection="column" sx={{ height:'300px', display: 'flex' }}>
            <Box flex={1} display="flex" flexDirection="column">
              <DialogTitle sx={{ mb:0, pb:0, fontWeight:'bold' }}>{ feedbackData.feedbackModalHeadline }</DialogTitle>
              <Typography variant='h6' sx={{ fontSize:'14px', margin:'0 25px 20px' }}>{ feedbackData.rating == 1 ? 'Encourage More Responses Like This' : 'Let\'s Fix this Behavior!' }</Typography>
              <Textarea autoFocus multiline id="comment" aria-label="Feedback" sx={{ margin:'0 20px', padding:'10px', maxWidth:'85%', borderColor:'#e1e1e1'}} minRows={5} maxRows={8} placeholder={feedbackData.comment || 'Add your feedback'} onChange={handleFeedbackChange} />
            </Box>
            <Box alignSelf="flex-end" sx={{ width:'100%' }}>
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button variant='outlined' onClick={() => setFeedbackData({ ...feedbackData, open: false })}>Cancel</Button>
                <Button variant='outlined' onClick={handleFeedbackSubmit}>Send Feedback</Button>
              </DialogActions>
            </Box>
          </Box>
          <Box flex={1} p={0}>
          <img
            src={feedbackData.rating === 1 ? 'happy-bot-work-party.webp' : 'sad-bot.webp'}
            alt="Feedback Type"
            style={{ width: '100%', height: 'auto' }}
          />
          </Box>
        </Box>
      </Dialog>
      </>
    );
  }
  
  export default ChatDetail;
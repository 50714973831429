import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PlatformRoute = ({ children }) => {
    let { isAuthenticated, checkAuthentication, checkPlatformUser } = useAuth();
    const [isPlatformUser, setIsPlatformUser] = useState(false);
    const location = useLocation();

    useEffect(() => {
        checkAuthentication();
    }, [location]);

    //return isAuthenticated ? (isPermitted ? children : <Navigate to="/dashboard" />) : <Navigate to="/login" />;
    return isAuthenticated && checkPlatformUser() ? children : <Navigate to="/dashboard" />;
};

export default PlatformRoute;

import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableRow, TableCell, Paper, Button } from '@mui/material';
import ChatGradeItem from './ChatGradeItem';
import { BeatLoader } from 'react-spinners';

function ChatGrade({chat}) {
    
    const [chatGrade, setChatGrade] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const gradeChat = async () => {
        setIsLoading(true); 

        if (!chat) {
            setIsLoading(false); 
            return; // If there's no chat selected, don't fetch
        }

        try {
            // make a post request to the endpoint
            const response = await fetch(process.env.REACT_APP_CHAT_SERVER_URL + `/grade/${chat.chatUUID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({})
            });
            const data = await response.json();
            let grade = data.message;
            //console.log("++++++++++++++++++");
            //console.log(data);
            //console.log("++++++++++++++++++");

            // grade is a string that should be valid JSON, so parse it
            try {
                grade = JSON.parse(grade);
            } catch (error) {
                // Had trouble parsing the string. Let's remove \n and tabs and try again
                grade = grade.replace(/\n/g, '');
                grade = grade.replace(/\t/g, '');
                grade = JSON.parse(grade);
            }

            setChatGrade(grade); // Update state with the fetched chat details
        } catch (error) {
            console.error('Failed to fetch chat details:', error);
        } finally {
            setIsLoading(false); 
        }
    };

    useEffect(() => {
        // This function is to fetch chat details from an endpoint
        const fetchChatGrade = async () => {
            if (!chat){ 
                console.log("NOT FETCHING");
                return; // If there's no chat selected, don't fetch
            }
            try {
                const response = await fetch(process.env.REACT_APP_CHAT_SERVER_URL + `/grade/${chat.chatUUID}`); // Replace with your actual endpoint
                const data = await response.json();
                if (data.length === 0) {
                    setChatGrade(null);
                    return;
                }else if (data.error) {
                    setChatGrade(null);
                    return;
                }
                if(data && !data.error){
                    // remove id and chatUUID from the data
                    delete data.id;
                    delete data.chatUUID;
                    delete data.response;
                }
                setChatGrade(data); // Update state with the fetched chat details
            } catch (error) {
                setChatGrade(null);
                console.log('Failed to fetch chat details:', error);
            }
        };

        setChatGrade(null);
        fetchChatGrade();
    }, [chat]);

    return (
        <Box sx={{ width: '300px', mr: '20px' }}>
            <Box sx={{ width: '300px', border: 1, borderRadius: '8px', borderColor: 'rgb(230, 235, 241)', backgroundColor: '#fff', overflow: 'hidden', mt: '24px', p: 3 }}>
                <Table size="small" aria-label="chat meta data">
                    <TableBody>
                
                {chatGrade && chatGrade !== null ? (

                    Object.entries(chatGrade).map(([key, value], index) => (
                        <ChatGradeItem
                            key={key}
                            name={key}
                            value={value}
                            chat={chat}
                            index={index + 1}
                        />
                    ))
                ) : (
                    <>
                        {isLoading ? (
                            <BeatLoader /> // Show spinner when loading
                        ) : (
                            <Button onClick={() => gradeChat()}>Grade This Chat</Button>
                        )}
                    </>
                )}
                </TableBody>
                </Table>
            </Box>
        </Box>
    );
}

export default ChatGrade;